<template>
<div class="">

<div class="">
     <div class="page-banner">
         <img src="/dist/img/coach_banner.png" style="width:100%;" alt="page-banner">
         <!-- <div class="page-title">
            <h5 class="text-white">Practice/Event Sanctioning</h5>
         </div>         -->
            <div class="page-title">
              <div class="row mb-2 mt-2">
                <div class="col-sm-8">
                   <h1 class="m-0 text-white">Practice/Event Sanctioning</h1>
                </div>         
                <div class="col-md-4 brd-white">
                  <div class="text-right">
                    <ol class="breadcrumb float-sm-right p-0">
                    <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><Breadcrumbs/></li>
                    </ol>
                  </div>                  
                </div>          
              </div>  
           </div> 
    </div>

       <div class="row">
            <div class="col-md-8 mb-4 mt-4">
              <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert"></custom-alert>
               <div class="card card-body mb-1 pt-0"> 
                  <p class="mt-3 ">Take advantage of your club membership by sanctioning a practice or exhibition, hosting a GrassRoot
event, Basic Skills Clinic, Get on the Water Week event or sanctioned tournament.</p>
                
                  <ul class="eventsanction">
                        <!-- <li><a class="text-blue" target="_blank" href="https://www.teamusa.org/usa-water-ski/insurance"><strong>Practice & Exhibition Sanctioning</strong></a></li>
                        <li><a class="text-blue" target="_blank" href="https://www.sharelifeonthewater.com/host-basic-skills.html"><strong>Basic Skills Clinic</strong></a> – Introduce newcomers to the sport by teaching the basic skills necessary to
                        water ski.</li> -->
                        <li><a class="text-blue"  href="https://www.sharelifeonthewater.com/host-basic-skills.html" target="_blank"><strong>Basic Skills Clinic</strong></a> – Introduce newcomers to the sport by teaching the basic skills necessary to
                        water ski.</li>
                        <li><a class="text-blue" target="_blank" href="https://www.sharelifeonthewater.com/skifree.html"><strong>Get on the Water Week Event</strong></a> – Annual promotion in late June/early July designed to bring
                        newcomers to the sport. Free sanctioning for clubs and free guest memberships for all
                        participants.</li>
                        <li><a class="text-blue" target="_blank" href="http://www.usawaterski.org/sanctions/default.aspx"><strong>GrassRoots/Tournament Sanctioning </strong></a>– Sanction a GrassRoots event or a competitive water ski,
                        wakeboard, kneeboard, barefoot and collegiate tournament.</li>
                    <li><a :href="sanctioningUrl" :class="[expired?'text-gray':'text-blue']" @click="checkExpires();" :disabled="[expired?true:false]" :title="alertMessage"><strong>Purchase Practice & Exhibition Sanctioning</strong> <span class="text-danger" v-if="emptyPrice">(Purchase is disabled now)</span></a></li>
                  </ul>
               </div>
            </div>
            <div class="col-md-4 status_bar mt-4">
               <div class="right-sidebarlink">
                      <ul class="sidebarlink">
  <li><router-link class="" to="/club/club-insurance-information">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="far fa-address-card"></i> Club Insurance(s) </h3>
                    </div>
                    </div>
            </router-link></li>
            <li><router-link class="" to="/club/quick-link-resources">

                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Quick Link Resources</h3>
                    </div>
                    </div>
            </router-link></li>
            <li>
                <a target="_blank" class="" href="https://www.teamusa.org/usa-water-ski/safe-sport">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Athlete Safety/Safe Sport</h3>
                    </div>
                    </div>
            </a>
            </li>
             <li><router-link class="" to="/club/practice-event-sanctioning">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"> <i class="fas fa-dharmachakra"></i> Practice and Event Sanctioning</h3>
                    </div>
                    </div>
            </router-link></li>
            <li>
                <router-link class="" to="/club/club-event-merchandise">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="fas fa-tags"></i> Club/Event Merchandise</h3>
                    </div>
                    </div>
            </router-link>
            </li>
            <li><router-link class="" to="/club/award-trophy-discounts">
                    <div class="card mb-2">
                    <div class="card-header rm-border bggrdient">
                    <h3 class="text-white"><i class="fas fa-tags"></i> Award/Trophy Discounts</h3>
                    </div>
                    </div>
            </router-link></li>
                    
                     
                 </ul>
                  <!-- <clubpagesidebarmenu></clubpagesidebarmenu> -->
               </div>
            </div>
         </div>

</div>

</div>
</template>


<script>
//import clubpagesidebarmenu from "./components/ClubPagesidebar";
import CustomAlert from "./../../CustomAlert";
import axios from "axios";
import moment from "moment";

export default {
  name: "practiceeventsanction",
  components: {CustomAlert},
  data() {
    return {
      sanctioningUrl: "javascript:void(0);",
      alertMessage: "",
      displayAlert: "",
      expired: false,
      emptyPrice: false
    }
  },
  methods: {
    checkExpires() {
      if(this.emptyPrice) return;
      if(this.expired) {
        this.alertMessage = "Please renew club membership!";
        this.displayAlert = true;
      } else {
        this.alertMessage = "Please wait for a while!";
        this.displayAlert = true;
      }
    },
    loadClubInfo() {
      axios
          .get(this.basePath + "api/club/user_verify", { headers: this.clubHeaders })
          .then((response) => {
            this.expired = false;
            const resp = response.data.data;
            this.current_sanction_fee = resp.current_sanction_fee;
            if(moment(new Date()).isAfter(moment(new Date(resp.valid_thru)))) {
              this.alertMessage = "Please renew club membership!";
              this.displayAlert = true;
              this.expired = true;
              this.sanctioningUrl = "javascript:void(0);";
            } else {
              console.log('ssss');
              this.sanctioningUrl = "/club/purchase-sanctioning";
            }
            if(this.current_sanction_fee === 0 || isNaN(this.current_sanction_fee)) {
              this.sanctioningUrl = "javascript:void(0);";
              this.emptyPrice = true;
              this.expired = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
  mounted() {
    this.loadClubInfo();
  }
}
</script>